<template>
    <div>
        <div class="detail">
            <img src="~assets/img/siFa/sifa_01.png" alt="">
            <img src="~assets/img/siFa/sifa_02.png" alt="" @click="bottomClick">
            <img src="~assets/img/siFa/detail_02.png" alt="">
            <img src="~assets/img/siFa/detail_03.png" alt="">
            <img src="~assets/img/siFa/detail_04.png" alt="">
            <img src="~assets/img/siFa/detail_05.png" alt="">
        </div>
        <div class="code" v-show="isShow" @touchmove.prevent>
            <img src="~assets/img/code.png" alt="" @click="imgClick">
        </div>
        <div class="bottom">
            <div class="click" @click="bottomClick">
                预约咨询
            </div>
        </div>
    </div>
</template>

<script>
    import { wxShareMixin } from "common/mixin";

    export default {
        name: "SiFa",
        data() {
            return {
                isShow: false
            }
        },
        mixins: [wxShareMixin],
        created() {
            this.wxShare()
            this.wxShareMessage('司法亲子鉴定',
                '司法亲子鉴定是DNA亲子鉴定的一种，但是具有法律效力，主要用于上户口、移民、留学、打官司等...',
                '/img/sifa.png')
        },
        methods: {
            bottomClick() {
                this.isShow = !this.isShow
            },
            imgClick() {
                this.isShow = !this.isShow
            }
        }
    }
</script>

<style scoped>
    .detail{
        margin-bottom: 9%;
        /*消除图片间隙*/
        font-size: 0;
    }
    .detail img {
        width: 100%;
    }
    .code{
        background-color:rgba(0,0,0,0.8);
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        /*width: 56vh;*/
        height: 100%;
        z-index: 99;
    }
    .code img{
        width: 80%;
        position: relative;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    .bottom{
        height: 9%;
        background-color: #fff;
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
    }
    .click{
        /*background-color: #d16061;*/
        background:linear-gradient(to right,#f1b86b,#d16061);
        width: 55%;
        height: 66%;
        color: #ffffff;
        font-size: 2.5vh;
        position: relative;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        border-radius: 80px;
        display: flex;
        text-align: center;
        flex-direction: column;
        justify-content: center;
    }
</style>